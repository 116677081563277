import React, { useState } from 'react';
import Select from 'react-select';
import { ColourStyles } from '../../components/Pagination/Pagination';
import { useStores } from '../../stores/StoreDistributor';
import { TextInput, TextInputType } from '../InputFields/TextInput/TextInput';

export type LocaleSettingsProps = {
  thousandSeparator: string;
  decimalSeparator: string;
  timeZone: string;
  dateFormat: string;
  onSelectChanged: (
    thousand: string | undefined,
    decimal: string | undefined,
    zone: string | undefined,
    format: string | undefined
  ) => void;
};

export const DeleteCollectionModalContent = () => {
  return (
    <React.Fragment>
      <p className='fls-font__small-regular'>Do you want to delete the collection?</p>
      <p className='fls-font__small-regular'>This action can't be undone.</p>
    </React.Fragment>
  );
};

export const RenameCollectionModalContent = ({ inputValue }: { inputValue: (newCollectionName: string) => void }) => {
  return (
    <div>
      <TextInput
        type='regular'
        label='New search collection name'
        placeholder='New search collection'
        autoFocus={true}
        callBack={inputValue}
      />
    </div>
  );
};

export const SpcFeedbackModalContent = () => {
  return (
    <React.Fragment>
      <p className='fls-font__small-regular'>Please share about your experience to improve the application.</p>
    </React.Fragment>
  );
};

export const NominatePartModalContent = () => {
  return (
    <React.Fragment>
      <p className='fls-font__small-regular'>Click here to propose adding new parts to the Global Service Catalogue.</p>
    </React.Fragment>
  );
};

export const AddNewPortalModal = () => {
  const { allowPortalStore } = useStores();

  return (
    <React.Fragment>
      <label className='fls-font__medium-bold newpart-modal'>Part Number</label>
      <TextInput
        type='add-part'
        textInputType={TextInputType.AllowPortalAddPart}
        label=''
        placeholder='Enter part number'
        autoFocus={true}
        callBack={(value: string) => (allowPortalStore.addPartNumber = value)}
      />
    </React.Fragment>
  );
};

//Start modal popup for displaying response of add new part in case the part is not added
export const EnoviaAvilabilityForPartNumberModal=()=>
{
   const {allowPortalStore} = useStores();
    if(allowPortalStore.addPartResult.result?.enoviaPartDetails==undefined && allowPortalStore.addPartResult.result?.dbStatus==false && allowPortalStore.addPartResult.result?.enoviaStatus==true)
    {
      return(
        <React.Fragment>         
            <span className='fls-font__centerText'>The part number already exists in GSC system.</span>                      
      </React.Fragment>
    
      );
    }
else if(allowPortalStore.addPartResult.result?.enoviaPartDetails!=undefined && allowPortalStore.addPartResult.result?.enoviaPartDetails?.[0].exists==false)
{
   return(
    <React.Fragment>
      <table>
        <thead>
          <body>
          <tr >
          <div className='fls-allow-portal-modal__excel-result fls-font__wrapTextWithScroll'>
      <div className='fls-allow-portal-modal__excel-resultWithTextWrap__line'>
        <label className='fls-allow-portal-modal__excel-result__line__label'>Part(s) Not Added</label>
        <label className='fls-allow-portal-modal__excel-result__line__label'>
          {allowPortalStore.addPartResult.result?.enoviaPartDetails?.[0].partNumber}
        </label>
      </div>
      </div>
        </tr>
          </body>
        </thead>     
      </table>
       
            
    </React.Fragment>
   );
  }
   
else  
{
  return(
    <div>
      <label className='fls-font__small-regular'>There is some issue at enovia side. Currently unable to fetch the details.</label>

    </div>
  );
}
};
//End 
//Start modal popup for displaying response when the new part is added to enovia and the part gets added 
export const EnoviaAddPartSuccessModal=()=>
  {
     const {allowPortalStore} = useStores();

     return(
      <React.Fragment>
        <table>
          <thead>
            <body>
            <tr>             
            <span className='fls-font__centerText'> {allowPortalStore.addPartResult.result?.enoviaPartDetails?.[0].partNumber} added successfully.</span> 
          </tr>        
            </body>
          </thead>     
        </table>
         
              
      </React.Fragment>
     );
  }
  //End 
  //Start modal popup for displaying when the part number is null
export const HandleEmptyPartNumberModal=()=>
  {
     return(
      <React.Fragment>
        <table>
          <thead>
            <body>
            <tr>             
            <span className='fls-font__centerText'>Part number is blank. Please enter the part number.</span> 
          </tr>        
            </body>
          </thead>     
        </table>
         
              
      </React.Fragment>
     );
  }
  //End 

export const ApproveDrawingModalContent = () => {
  return (
    <React.Fragment>
      <p className='fls-font__small-regular'>Confirm Part Approval?</p>
    </React.Fragment>
  );
};

export const NewCollectionModalContent = ({
  inputValue,
  setPrivateBoolean,
}: {
  inputValue: (collectionName: string) => void;
  setPrivateBoolean: (isPrivate: boolean) => void;
}) => {
  const [isPrivate, setIsPrivate] = useState<boolean>(false);
  const toggleIsPrivate = () => {
    setIsPrivate(!isPrivate);
    setPrivateBoolean(!isPrivate);
  };
  return (
    <div>
      <TextInput
        type='regular'
        label='Saved search name:'
        placeholder='New saved search'
        autoFocus={true}
        callBack={inputValue}
      />
      <div className='fls-modal__fls-checkbox'>
        <label className='fls-checkbox__wrapper'>
          <input
            onChange={toggleIsPrivate}
            className='fls-checkbox__input'
            type='checkbox'
            id={'privateCheckbox'}
            name={'Private Search'}
            checked={isPrivate}
          />
          <span className='fls-checkbox__checkmark'></span>
          <label className='fls-checkbox__label fls-font__xsmall-regular' htmlFor={'privateCheckbox'}>
            Save as private search.
          </label>
        </label>
      </div>
    </div>
  );
};

export function NewLocaleSettingsModalContent({
  thousandSeparator,
  decimalSeparator,
  timeZone,
  dateFormat,
  onSelectChanged,
}: LocaleSettingsProps) {
  const separatorOptions = [
    { value: '.|,', label: '2.000,35' },
    { value: ',|.', label: '2,000.35' },
    { value: ' |,', label: '2 000,35' },
  ];

  const timeZoneOptions = [
    { value: 'Pacific Standard Time', label: '(UTC-08:00) Pacific (US & Canada) - DST' },
    { value: 'Mountain Standard Time', label: '(UTC-07:00) Mountain (US & Canada) - DST' },
    { value: 'Central Standard Time', label: '(UTC-06:00) Central (US & Canada) - DST' },
    { value: 'Eastern Standard Time', label: '(UTC-05:00) Eastern (US & Canada) - DST' },
    { value: 'GMT Standard Time', label: '(UTC+00:00) Dublin, Edinburgh, Lisbon, London - DST' },
    { value: 'UTC', label: '(UTC+00:00) Coordinated Universal Time' },
    { value: 'Romance Standard Time', label: '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris - DST' },
    { value: 'India Standard Time', label: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi' },
    { value: 'AUS Eastern Standard Time', label: '(UTC+10:00) Canberra, Melbourne, Sydney - DST' },
  ];

  const timeFormatOptions = [
    { value: 'YYYY-MM-DD', label: '2023-12-31 - YYYY-MM-DD' },
    { value: 'DD-MM-YYYY', label: '31-12-2023 - DD-MM-YYYY' },
    { value: 'MM-DD-YYYY', label: '12-31-2023 - MM-DD-YYYY' },
    { value: 'DD/MM-YYYY', label: '31/12-2023 - DD/MM-YYYY' },
    { value: 'MM/DD-YYYY', label: '12/31-2023 - MM/DD-YYYY' },
    { value: 'YYYY-MM/DD', label: '2023-12/31 - YYYY-MM/DD' },
    { value: 'YYYY/MM/DD', label: '2023/12/31 - YYYY/MM/DD' },
    { value: 'DD/MM/YYYY', label: '31/12/2023 - DD/MM/YYYY' },
    { value: 'MM/DD/YYYY', label: '12/31/2023 - MM/DD/YYYY' },
  ];

  const separatorLabel = separatorOptions.find(
    (option) => option.value === thousandSeparator + '|' + decimalSeparator
  )?.label;
  const timeZoneLabel = timeZoneOptions.find((option) => option.value === timeZone)?.label;
  const timeformatLabel = timeFormatOptions.find((option) => option.value === dateFormat)?.label;

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <label className='fls-font__medium-regular' htmlFor={'Separators'}>
        Decimal Separators{' '}
      </label>
      <Select
        isSearchable={false}
        defaultValue={{
          value: thousandSeparator + '|' + decimalSeparator,
          label: separatorLabel,
        }}
        onChange={(selected: any) => {
          let separators = selected.value.split('|');
          onSelectChanged(separators[0], separators[1], undefined, undefined);
        }}
        options={separatorOptions}
        styles={ColourStyles}
        menuPlacement='bottom'
        components={{
          IndicatorSeparator: () => null,
        }}
      />
      <span style={{ height: '30px' }}></span>

      <label className='fls-font__medium-regular' htmlFor={'DateFormat'}>
        Date format{' '}
      </label>
      <Select
        isSearchable={false}
        defaultValue={{
          value: dateFormat,
          label: timeformatLabel,
        }}
        onChange={(selected: any) => {
          let selectedDateFormat = selected.value;
          onSelectChanged(undefined, undefined, undefined, selectedDateFormat);
        }}
        options={timeFormatOptions}
        styles={ColourStyles}
        menuPlacement='bottom'
        components={{
          IndicatorSeparator: () => null,
        }}
      />
      <span style={{ height: '30px' }}></span>

      <label className='fls-font__medium-regular' htmlFor={'TimeZone'}>
        Time zone (uses daylight savings time)
      </label>
      <Select
        isSearchable={false}
        defaultValue={{
          value: timeZone,
          label: timeZoneLabel,
        }}
        onChange={(selected: any) => {
          let selectedTimeZone = selected.value;
          onSelectChanged(undefined, undefined, selectedTimeZone, undefined);
        }}
        options={timeZoneOptions}
        styles={ColourStyles}
        menuPlacement='bottom'
        components={{
          IndicatorSeparator: () => null,
        }}
      />
    </div>
  );
}
