import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import * as React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { AuthorizeView, RoleRequirement } from '../../../components/AuthorizeView/AuthorizeView';
import { Button } from '../../../components/Button/Button';
import Pagination from '../../../components/Pagination/Pagination';
import { RoleTypes } from '../../../constants/GenericContants';
import { cancelIcon } from '../../../foundation/svg/cancel-icon';
import { LoadingSpinner } from '../../../foundation/svg/loading-spinner';
import { useStores } from '../../../stores/StoreDistributor';
import { Client, IPCCustomerPartDetails, PartDetails, PCCustomerPartDetails } from '../../ApiClient/ApiClient';
import { PartDataTable } from './PartDataTable';

type PossibleItemsPerPage = 10 | 25 | 50 | 100;
interface UploadResponse {
  updatedPart?: number;
  addedPart?: number;
}

export const PartOverviewpage = () => {
  const { customerId } = useParams();
  const query = new URLSearchParams(useLocation().search);
  const customerCurrency = query.get('currency');

  const { CustomerPartDetailStore } = useStores();
  const paginationMaxLength = 3;

  const setItemsPerPage = (n: PossibleItemsPerPage) => {
    CustomerPartDetailStore.itemsPerPage = n;
    CustomerPartDetailStore.currentPage = 1;
  };
  async function loadData() {
    if (customerId) {
      setCusDataLoading(true);
      try {
        await CustomerPartDetailStore.updateCustomerPartFetch();
        setLocalSPDetailData(CustomerPartDetailStore.customerPartResult);
      } catch (error) {
        setLocalSPDetailData(null);
      } finally {
        setCusDataLoading(false);
      }
    }
  }

  React.useEffect(() => {
    CustomerPartDetailStore.fetchSearchParamsFromUrl();
    loadData();
  }, []);

  const isFormValid = (): boolean => {
    return (
      partNo.trim() !== '' &&
      custpartNo.trim() !== '' &&
      price.trim() !== '' &&
      parseFloat(price) >= 1.0 &&
      incoTerm.trim() !== '' &&
      incotermLocation.trim() !== '' &&
      leadTime.trim() !== '' &&
      minimumOrderQuantity.trim() !== '' &&
      unitOfMeasure.trim() !== ''
    );
  };
  async function addNewPart() {
    if (isFormValid()) {
      let apiClient = new Client();

      const partdetails = new PartDetails();
      partdetails.customerId = customerId;
      partdetails.customerPartId = custpartNo;
      partdetails.currency = customerCurrency != null ? customerCurrency : '';
      partdetails.partId = partNo;
      partdetails.price = price;
      partdetails.incoTerm = incoTerm;
      partdetails.incoTermLocation = incotermLocation;
      partdetails.leadTime = leadTime;
      partdetails.unitOfMeasure = unitOfMeasure;
      partdetails.minimumOrderQuantity = minimumOrderQuantity;
      setCusDataLoading(true);
      setAddnewPartOpen(false);
      await apiClient.createCustomerPart(partdetails);
      await loadData();
      setCusDataLoading(false);
    }
  }

  async function deletePart() {
    let apiClient = new Client();

    const partdetails = new PartDetails();
    partdetails.customerId = value?.customerId;
    partdetails.customerPartId = value?.customerPartId;
    partdetails.currency = value?.currency;
    partdetails.partId = value?.partId;
    partdetails.price = value?.price;
    partdetails.unitOfMeasure = value?.unitOfMeasure;
    partdetails.minimumOrderQuantity = value?.minimumOrderQuantity;
    setCusDataLoading(true);
    setDelPartOpen(false);
    await apiClient.deleteCustomerPart(partdetails);
    const isLastItemOnPage =
      CustomerPartDetailStore.totalCustomers - 1 <=
      (CustomerPartDetailStore.currentPage - 1) * CustomerPartDetailStore.itemsPerPage;
    if (isLastItemOnPage && CustomerPartDetailStore.currentPage > 1) {
      CustomerPartDetailStore.currentPage = CustomerPartDetailStore.currentPage - 1;
      CustomerPartDetailStore.setSearchParamsInUrl();
    }
    await loadData();
    setCusDataLoading(false);
  }

  async function uploadPart() {
    let apiClient = new Client();
    const uploadPartData = new PCCustomerPartDetails();
    try {
      const data = await convertExceltoJson();
      if (data) {
        uploadPartData.partDetails = data;
        uploadPartData.totalCount = data.length;
        setCusDataLoading(true);
        setUploadPartOpen(false);

        const uploadRes = await apiClient.uploadCustomerPart(uploadPartData);
        setUploadedResponse(uploadRes);
        loadData();
        if (uploadRes) {
          setsuccessnewPartOpen(true);
        }
        setCusDataLoading(false);
        setSelectedFile(null);
      }
    } catch (error) {
      setSelectedFile(null);
      setUploadErrorText(error as string);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  }

  const [addnewpartopen, setAddnewPartOpen] = React.useState(false);
  const [delpartopen, setDelPartOpen] = React.useState(false);
  const [uploadpartopen, setUploadPartOpen] = React.useState(false);
  const [value, setValue] = React.useState<PartDetails | undefined>();
  const [localSPDetailData, setLocalSPDetailData] = React.useState<IPCCustomerPartDetails | undefined | null>();

  const [partNo, setPartNo] = React.useState<string>('');
  const [custpartNo, setCustPartNo] = React.useState<string>('');

  const [price, setPrice] = React.useState<string>('');
  const [incoTerm, setIncoTerm] = React.useState<string>('');
  const [incotermLocation, setIncotermLocation] = React.useState<string>('');
  const [leadTime, setLeadTime] = React.useState<string>('');
  const [minimumOrderQuantity, setMOQ] = React.useState<string>('');
  const [unitOfMeasure, setUOM] = React.useState<string>('');

  const [successnewpartopen, setsuccessnewPartOpen] = React.useState(false);
  const [cusDataLoading, setCusDataLoading] = React.useState<boolean>(false);
  const [uploadedResponse, setUploadedResponse] = React.useState<UploadResponse | null>(null);
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const handleClickPartDelete = (props: PartDetails | undefined) => {
    setDelPartOpen(true);
    setValue(props);
  };

  //file upload & drag and drop
  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
  const [uploadErrorText, setUploadErrorText] = React.useState<String | null>(null);
  const validFileTypes = [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
    'text/csv',
  ];

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUploadErrorText(null);
    const file = event.target.files?.[0] || null;
    if (file && validFileTypes.includes(file.type) && file.name === 'Pricing_Upload_Template.xlsx') {
      setSelectedFile(file);
    } else {
      setUploadErrorText('Please Upload a Valid Excel File');
      setSelectedFile(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setUploadErrorText(null);
    const file = event.dataTransfer.files?.[0] || null;
    if (file && validFileTypes.includes(file.type) && file.name === 'Pricing_Upload_Template.xlsx') {
      setSelectedFile(file);
    } else {
      setUploadErrorText('Please Upload a Valid Excel File');
      setSelectedFile(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  function handleClick() {
    const inputElement = document.querySelector('.fls-text-input-PC__input-field-PC') as HTMLInputElement;
    if (inputElement) {
      inputElement.click();
    }
  }

  function handleFileUploadClear() {
    setSelectedFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  }
  function addnewPartCloseDialog() {
    setAddnewPartOpen(false);
    setPartNo('');
    setCustPartNo('');
    setPrice('');
    setIncoTerm('');
    setIncotermLocation('');
    setLeadTime('');
    setMOQ('');
    setUOM('');
  }
  function uploadPartCloseDialog() {
    setUploadPartOpen(false);
    setUploadErrorText(null);
    setSelectedFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  }

  const handlePriceInput = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    const priceInputElement = e.target as HTMLInputElement;
    const priceInputValue = priceInputElement.value;
    if (e.key === 'Backspace' || e.key === 'Tab' || e.key.startsWith('Arrow')) {
      return;
    }
    if (!/^\d$/.test(e.key) && e.key !== '.') {
      e.preventDefault();
    }
    if (e.key === '.' && priceInputValue.includes('.')) {
      e.preventDefault();
    }
    const decimalIndex = priceInputValue.indexOf('.');
    if (decimalIndex !== -1 && priceInputValue.length - decimalIndex > 2) {
      e.preventDefault();
    }
  };

  const handleMOQInput = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Backspace' || e.key === 'Tab' || e.key.startsWith('Arrow')) {
      return;
    }
    if (!/^\d$/.test(e.key)) {
      e.preventDefault();
    }
  };

  const localStringToNumber = (s: string): number => {
    return Number(String(s).replace(/[^0-9.-]+/g, ''));
  };

  const onPriceChangeFocus = (e: React.FocusEvent<HTMLInputElement>): void => {
    const currentValue = localStringToNumber(e.target.value);
    if (currentValue === 0) {
      e.target.value = '';
    } else {
      e.target.value = currentValue ? currentValue.toString() : '';
    }
  };

  const onMOQChangeFocus = (e: React.FocusEvent<HTMLInputElement>): void => {
    const currentValue = localStringToNumber(e.target.value);
    if (currentValue === 0) {
      e.target.value = '';
    } else {
      e.target.value = currentValue ? currentValue.toString() : '';
    }
  };

  const onPriceChangeBlur = (e: React.FocusEvent<HTMLInputElement>): void => {
    const numericValue = localStringToNumber(e.target.value);
    e.target.value = numericValue || numericValue === 0 ? numericValue.toFixed(2) : '';

    setPrice(e.target.value);
  };

  const onMOQChangeBlur = (e: React.FocusEvent<HTMLInputElement>): void => {
    const numericValue = localStringToNumber(e.target.value);
    e.target.value = numericValue || numericValue === 0 ? numericValue.toString() : '';

    setMOQ(e.target.value);
  };

  async function convertExceltoJson(): Promise<PartDetails[]> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;

      reader.onload = (e) => {
        try {
          const bstr = e.target?.result;
          if (!bstr) {
            reject('No data loaded');
            return;
          }
          const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });
          const wsname = wb.SheetNames[0];
          if (wsname !== 'PartDetails') {
            reject('Excel Sheet Name Missmatch. Please Upload a Valid Excel');
            return;
          }
          const ws = wb.Sheets[wsname];
          const headers = XLSX.utils.sheet_to_json<string[]>(ws, { header: 1 })[0];
          const expectedHeaders = [
            'PartId',
            'CustomerPartId',
            'Price',
            'Incoterm Location',
            'Incoterm',
            'Lead Time',
            'UnitOfMeasure',
            'MinimumOrderQuantity',
          ];
          const isValidHeaders =
            expectedHeaders.every((header) => headers.includes(header)) && headers.length === expectedHeaders.length;
          if (!isValidHeaders) {
            reject('Invalid columns in the Excel file.');
            return;
          }
          let data: PartDetails[] = XLSX.utils.sheet_to_json(ws);
          if (data.length === 0) {
            reject('The Excel sheet contains no data.');
            return;
          }
          const partDetailsArray = data.map((item: any) => {
            let price = parseFloat(item.Price);
            console.log(price);
            console.log(item.Price);
            if (isNaN(price) || price <= 0) {
              reject(`Invalid or Zero price value at price column.`);
              throw new Error();
            }
            const formattedPrice = price.toFixed(2);

            let moq = parseInt(item.MinimumOrderQuantity);
            console.log(moq);
            console.log(item.MinimumOrderQuantity);

            if (isNaN(moq) || moq < 0) {
              reject(`Invalid value at Minimum Order Quantity.`);
              throw new Error();
            }
            if (!item.PartId || !item.CustomerPartId) {
              reject(`Please enter data for all the columns`);
              throw new Error();
            }
            return new PartDetails({
              partId: item.PartId,
              customerPartId: item.CustomerPartId,
              price: formattedPrice,
              customerId: customerId,
              currency: customerCurrency ? customerCurrency : 'missing currency',
              incoTerm: item.Incoterm?.toString(),
              incoTermLocation: item['Incoterm Location']?.toString(),
              leadTime: item['Lead Time']?.toString(),
              unitOfMeasure: item['UnitOfMeasure']?.toString(),
              minimumOrderQuantity: item['MinimumOrderQuantity']?.toString(),
            });
          });
          resolve(partDetailsArray);
        } catch (error) {
          reject(error);
        }
      };
      reader.onerror = (error) => reject(error);
      if (rABS) {
        selectedFile && reader.readAsBinaryString(selectedFile);
      } else {
        selectedFile && reader.readAsArrayBuffer(selectedFile);
      }
    });
  }

  const isPartDetails =
    localSPDetailData && localSPDetailData?.partDetails && localSPDetailData?.partDetails?.length > 0;

  return (
    <AuthorizeView roles={[RoleTypes.SPCAdmin]} roleRequirement={RoleRequirement.Required}>
      <div className='fls-sp-collection'>
        <div>
          <Dialog
            open={delpartopen}
            onClose={() => setDelPartOpen(false)}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
            fullWidth
          >
            <DialogTitle className='fls-model-PC fls-model-PC__header'>
              <span className='fls-model-PC fls-model-PC__header'>Do you wish to delete {value?.partId} ? </span>
            </DialogTitle>
            <DialogActions>
              <button className='fls-model-PC fls-model-PC--delete' onClick={() => setDelPartOpen(false)}>
                <span>No</span>
              </button>
              <button className='fls-model-PC fls-model-PC--primary' onClick={() => deletePart()}>
                <span>Yes</span>
              </button>
            </DialogActions>
          </Dialog>
        </div>

        <div>
          <Dialog
            open={uploadpartopen}
            onClose={() => uploadPartCloseDialog()}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
            fullWidth
          >
            <DialogTitle sx={{ height: 10 }} className='fls-model-PC fls-model-PC__header'>
              <span>Upload Pricing Agreements </span>
            </DialogTitle>
            <div className='fls-part-upload-dialog-close-icon'>
              <IconButton
                aria-label='close'
                onClick={() => uploadPartCloseDialog()}
                sx={(theme) => ({
                  position: 'absolute',
                  right: 8,
                  top: 2,
                })}
              >
                {cancelIcon()}
              </IconButton>
            </div>
            <DialogContent>
              <DialogContentText>
                <div className='fls-part-upload-download-template-text'>
                  Pricing Upload Template :{' '}
                  <a href='/Pricing_Upload_Template.xlsx' download>
                    Download
                  </a>
                </div>
                <div className='fls-part-upload-download-template-text'>
                  Accepted File Type : <span style={{ color: '#3c50de' }}>Excel</span>
                </div>
              </DialogContentText>
              <div
                className='fls-part-upload-form'
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                onClick={handleClick}
              >
                <input
                  className='fls-text-input-PC__input-field-PC fls-font__medium-regular'
                  type='file'
                  ref={fileInputRef}
                  accept='.xlsx, .xls, .csv'
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                />
                {selectedFile ? (
                  <span className='fls-part-upload-fileName-text'>{selectedFile.name}</span>
                ) : (
                  <span className='fls-part-upload-fileName-text'>
                    Drag and drop a file or click to open a system window.
                  </span>
                )}
              </div>
              <br />
              {uploadErrorText && <span className='fls-part-upload-error-text'>{uploadErrorText}</span>}
            </DialogContent>
            <DialogActions>
              <button
                className='fls-model-PC fls-model-PC--delete'
                disabled={selectedFile == null}
                onClick={() => handleFileUploadClear()}
              >
                Clear
              </button>
              <button
                className='fls-model-PC fls-model-PC--primary'
                disabled={selectedFile == null}
                onClick={() => uploadPart()}
              >
                <span>Upload</span>
              </button>
            </DialogActions>
          </Dialog>
        </div>

        {/* upload success*/}
        <div>
          <Dialog
            open={successnewpartopen}
            onClose={() => setsuccessnewPartOpen(false)}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
            maxWidth={'xs'}
            fullWidth
          >
            <DialogTitle className='fls-model-PC fls-model-PC__header'>
              <span className='fls-model-PC fls-model-PC__header' style={{ fontSize: '1.6em' }}>
                Update Status
              </span>
            </DialogTitle>
            <DialogContent style={{ padding: '0px' }}>
              <DialogContentText id='alert-dialog-description'>
                <br />
                <div className='fls-update-status-container'>
                  <div className='fls-update-status-names'>
                    <label
                      className='fls-text-input-PC__label-field-PC fls-text-input-PC__center fls-font__medium-bold'
                      style={{ width: '100%', justifyContent: 'center' }}
                      htmlFor='fls-text-input-id'
                    >
                      Updated part(s)
                    </label>
                    <label
                      className='fls-text-input-PC__label-field-PC fls-text-input-PC__center fls-font__medium-bold'
                      htmlFor='fls-text-input-id'
                    >
                      Added part(s)
                    </label>
                  </div>
                  <div className='fls-update-status-vertical-line'></div>
                  <div className='fls-update-status-names'>
                    <label
                      className='fls-text-input-PC__label-field-PC fls-text-input-PC__center fls-font__medium-bold'
                      htmlFor='fls-text-input-id'
                    >
                      {uploadedResponse?.updatedPart}
                    </label>
                    <label
                      className='fls-text-input-PC__label-field-PC fls-text-input-PC__center fls-font__medium-bold'
                      htmlFor='fls-text-input-id'
                    >
                      {uploadedResponse?.addedPart}
                    </label>
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <div className='fls-update-success-close-button-container'>
                <button
                  className='fls-model-PC fls-model-PC--primary'
                  style={{ width: '50%' }}
                  onClick={() => {
                    setsuccessnewPartOpen(false);
                    setUploadedResponse(null);
                  }}
                >
                  <span>Ok</span>
                </button>
              </div>
            </DialogActions>
          </Dialog>
        </div>

        {/*PART ADD*/}
        <div>
          <Dialog
            open={addnewpartopen}
            onClose={() => setAddnewPartOpen(false)}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
            fullWidth
          >
            <DialogTitle className='fls-model-PC fls-model-PC__header'>
              <span className='fls-model-PC fls-model-PC__header'>Add new Pricing Agreement</span>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-description'>
                <br />
                <div className='fls-text-input-PC__input-container-PC fls-text-input-PC__input-container--regular'>
                  <label
                    className='fls-text-input-PC__label-field-PC fls-text-input-PC__center fls-font__medium-bold'
                    htmlFor='fls-text-input-id'
                  >
                    Part Number
                  </label>
                  <input
                    className='fls-text-input-PC__input-field-PC fls-font__medium-regular'
                    id='fls-text-input-id'
                    autoFocus={false}
                    placeholder={'Enter Part Number'}
                    onChange={(e) => setPartNo(e.target.value)}
                  />
                </div>
                <br />
                <div className='fls-text-input-PC__input-container-PC fls-text-input-PC__input-container--regular'>
                  <label
                    className='fls-text-input-PC__label-field-PC fls-text-input-PC__center fls-font__medium-bold'
                    htmlFor='fls-text-input-id'
                  >
                    Customer Part Number
                  </label>
                  <input
                    className='fls-text-input-PC__input-field-PC fls-font__medium-regular'
                    id='fls-text-input-id'
                    autoFocus={false}
                    placeholder={'Enter Customer Part Number'}
                    onChange={(e) => setCustPartNo(e.target.value)}
                  />
                </div>
                <br />
                <div className='fls-text-input-PC__input-container-PC fls-text-input-PC__input-container--regular'>
                  <label
                    className='fls-text-input-PC__label-field-PC fls-text-input-PC__center fls-font__medium-bold'
                    htmlFor='fls-text-input-id'
                  >
                    Price
                  </label>
                  <input
                    className='fls-text-input-PC__input-field-PC fls-font__medium-regular'
                    id='fls-text-input-id'
                    autoFocus={false}
                    placeholder={'Enter Price'}
                    onChange={(e) => setPrice(e.target.value)}
                    onKeyDown={handlePriceInput}
                    onFocus={onPriceChangeFocus}
                    onBlur={onPriceChangeBlur}
                  />
                </div>
                <br />
                <div className='fls-text-input-PC__input-container-PC fls-text-input-PC__input-container--regular'>
                  <label
                    className='fls-text-input-PC__label-field-PC fls-text-input-PC__center fls-font__medium-bold'
                    htmlFor='fls-text-input-id'
                  >
                    IncoTerm
                  </label>
                  <input
                    className='fls-text-input-PC__input-field-PC fls-font__medium-regular'
                    id='fls-text-input-id'
                    autoFocus={false}
                    placeholder={'Enter IncoTerm'}
                    onChange={(e) => setIncoTerm(e.target.value)}
                  />
                </div>
                <br />
                <div className='fls-text-input-PC__input-container-PC fls-text-input-PC__input-container--regular'>
                  <label
                    className='fls-text-input-PC__label-field-PC fls-text-input-PC__center fls-font__medium-bold'
                    htmlFor='fls-text-input-id'
                  >
                    IncoTerm Location
                  </label>
                  <input
                    className='fls-text-input-PC__input-field-PC fls-font__medium-regular'
                    id='fls-text-input-id'
                    autoFocus={false}
                    placeholder={'Enter IncoTerm Location'}
                    onChange={(e) => setIncotermLocation(e.target.value)}
                  />
                </div>
                <br />
                <div className='fls-text-input-PC__input-container-PC fls-text-input-PC__input-container--regular'>
                  <label
                    className='fls-text-input-PC__label-field-PC fls-text-input-PC__center fls-font__medium-bold'
                    htmlFor='fls-text-input-id'
                  >
                    Lead Time
                  </label>
                  <input
                    className='fls-text-input-PC__input-field-PC fls-font__medium-regular'
                    id='fls-text-input-id'
                    autoFocus={false}
                    placeholder={'Enter Lead Time'}
                    onChange={(e) => setLeadTime(e.target.value)}
                  />
                </div>
                <br />
                <div className='fls-text-input-PC__input-container-PC fls-text-input-PC__input-container--regular'>
                  <label
                    className='fls-text-input-PC__label-field-PC fls-text-input-PC__center fls-font__medium-bold'
                    htmlFor='fls-text-input-id'
                  >
                    Unit of Measure
                  </label>
                  <input
                    className='fls-text-input-PC__input-field-PC fls-font__medium-regular'
                    id='fls-text-input-id'
                    autoFocus={false}
                    placeholder={'Enter Unit of Measure'}
                    onChange={(e) => setUOM(e.target.value)}
                  />
                </div>
                <br />
                <div className='fls-text-input-PC__input-container-PC fls-text-input-PC__input-container--regular'>
                  <label
                    className='fls-text-input-PC__label-field-PC fls-text-input-PC__center fls-font__medium-bold'
                    htmlFor='fls-text-input-id'
                  >
                    Minimum Order Quantity
                  </label>
                  <input
                    className='fls-text-input-PC__input-field-PC fls-font__medium-regular'
                    id='fls-text-input-id'
                    autoFocus={false}
                    placeholder={'Enter Minimum Order Quantity'}
                    onChange={(e) => setMOQ(e.target.value)}
                    onKeyDown={handleMOQInput}
                    onFocus={onMOQChangeFocus}
                    onBlur={onMOQChangeBlur}
                  />
                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <button className='fls-model-PC fls-model-PC--delete' onClick={() => addnewPartCloseDialog()}>
                <span>Cancel</span>
              </button>
              <button
                className='fls-model-PC fls-model-PC--primary'
                disabled={!isFormValid()}
                onClick={() => addNewPart()}
              >
                <span>Add new Pricing Agreement</span>
              </button>
            </DialogActions>
          </Dialog>
        </div>

        <section className='fls-sp-detail__heading-section fls-sp-detail__heading-section--short'>
          <section className='fls-sp-search__input-section'>
            <div className='fls-sp-search__input-container'>
              <h1 className='fls-font__title'>Pricing Agreement Part Overview</h1>
              <div className='fls-sp-search__util-buttons-container'>
                <Button
                  type='fls-button fls-button--primary-with-icon-outline'
                  text={customerId || ''}
                  action={() => ''}
                ></Button>
                <Button
                  type='fls-button fls-button--primary-with-icon-outline'
                  text={customerCurrency || ''}
                  action={() => ''}
                ></Button>
                <Button
                  type='fls-button fls-button--primary'
                  text='Upload Parts'
                  action={() => setUploadPartOpen(true)}
                ></Button>

                <Button
                  type='fls-button fls-button--primary'
                  text='Add new Part'
                  action={() => setAddnewPartOpen(true)}
                ></Button>
              </div>
            </div>
          </section>
        </section>

        {cusDataLoading ? (
          <div className='fls-spinner__background'>
            <LoadingSpinner />
          </div>
        ) : isPartDetails ? (
          <>
            <PartDataTable data={localSPDetailData} deleteHandler={handleClickPartDelete} />
            <div className='container'>
              <Pagination
                currentPage={CustomerPartDetailStore.currentPage}
                lastPage={CustomerPartDetailStore.getLastPage}
                maxLength={paginationMaxLength}
                setCurrentPage={(newCurrentPage) => {
                  CustomerPartDetailStore.currentPage = newCurrentPage;
                  loadData();
                  CustomerPartDetailStore.setSearchParamsInUrl();
                }}
                itemsPerPage={CustomerPartDetailStore.itemsPerPage}
                setItemsPerPage={(n) => {
                  if (n === 10 || n === 25 || n === 50 || n === 100) {
                    setItemsPerPage(n);
                    CustomerPartDetailStore.setSearchParamsInUrl();
                  }
                }}
              />
            </div>
          </>
        ) : (
          <div className='fls-no-data-found'>
            <span className='fls-part-upload-download-template-text' style={{ fontSize: '2rem' }}>
              No records found for this customer!{' '}
            </span>
          </div>
        )}
      </div>
    </AuthorizeView>
  );
};
