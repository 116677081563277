import { observer } from 'mobx-react';
import { LoadingSpinner } from '../../../foundation/svg/loading-spinner';
import { useStores } from '../../../stores/StoreDistributor';
import { ApiCallState } from '../../ApiClient/ApiHelperTypes';
import { ExportToExcel } from './ExportToExcel';

export const ExcelUploadResultModal = observer(() => {
  const { allowPortalStore } = useStores();
  if (allowPortalStore.excelUploadResult.state === ApiCallState.loading) {
    return (
      <div className='fls-allow-portal-modal__excel-result__spinner'>
        <LoadingSpinner />
      </div>
    );
  }

  //check if any records with exists=false exist, so all records are not added. Hence we should see the download functionality else 0 records 
  let recordWithFalseExists=allowPortalStore.excelUploadResult.result?.addAllowListPartResponse?.enoviaPartDetails?.find(e=>e.exists==false);
  if(allowPortalStore.excelUploadResult.result?.addAllowListPartResponse?.enoviaStatus==true && recordWithFalseExists==undefined)
  {
  return (
    <div className='fls-allow-portal-modal__excel-result fls-font__medium-bold'>
      <div className='fls-allow-portal-modal__excel-result__line'>
        <label className='fls-allow-portal-modal__excel-result__line__label'>Update Part(s)</label>
        <label className='fls-allow-portal-modal__excel-result__line__label'>
          {allowPortalStore.excelUploadResult.result?.updatedPartNumbers?.length}
        </label>
      </div>
      <div className='fls-allow-portal-modal__excel-result__line '>
        <label className='fls-allow-portal-modal__excel-result__line__label'>Created Part(s)</label>
        <label className='fls-allow-portal-modal__excel-result__line__label'>
          {allowPortalStore.excelUploadResult.result?.createdPartNumbers?.length}
        </label>
      </div> 
      <div className='fls-allow-portal-modal__excel-result__line '>
        <label className='fls-allow-portal-modal__excel-result__line__label'>Not Created Part(s)</label>
        <label className='fls-allow-portal-modal__excel-result__line__label'>
          0
        </label>
      </div>           
    </div>
  );
}
else if(allowPortalStore.excelUploadResult.result?.addAllowListPartResponse?.enoviaStatus==true && recordWithFalseExists!=undefined)
 {
  return (
    <div className='fls-allow-portal-modal__excel-result fls-font__medium-bold'>
      <div className='fls-allow-portal-modal__excel-result__line'>
        <label className='fls-allow-portal-modal__excel-result__line__label'>Update Part(s)</label>
        <label className='fls-allow-portal-modal__excel-result__line__label'>
          {allowPortalStore.excelUploadResult.result?.updatedPartNumbers?.length}
        </label>
      </div>
      <div className='fls-allow-portal-modal__excel-result__line '>
        <label className='fls-allow-portal-modal__excel-result__line__label'>Created Part(s)</label>
        <label className='fls-allow-portal-modal__excel-result__line__label'>
          {allowPortalStore.excelUploadResult.result?.createdPartNumbers?.length}
        </label>
      </div> 
      <ExportToExcel/>   
    </div>
  );
}
else
{
  return(
    <div>
      <label className='fls-font__small-regular'>There is some issue at enovia side. Currently unable to fetch the details.</label>
    </div>
  );
}
});
