import { observer } from 'mobx-react';
import * as XLSX from "xlsx";
import { useStores } from '../../../stores/StoreDistributor';


export const ExportToExcel = observer(() =>{
      const { allowPortalStore } = useStores();

  
  const exportToXLSX = () => {
      if(allowPortalStore.excelUploadResult.result?.addAllowListPartResponse?.enoviaPartDetails!==undefined)
      {
        const modifiedData = allowPortalStore.excelUploadResult.result?.addAllowListPartResponse?.enoviaPartDetails.map((row) => {
          return {
            PartNumber: row.partNumber, 
            Enovia_Availability: row.exists, 
          };
        });
        var notExistingPartsInEnovia=modifiedData.filter(e=>e.Enovia_Availability==false);
        const worksheet = XLSX.utils.json_to_sheet(notExistingPartsInEnovia);     
        const workbook = XLSX.utils.book_new(); 
        //append data to sheet
        XLSX.utils.book_append_sheet(workbook, worksheet, "NotAddedParts");
        XLSX.writeFile(workbook, "PartsNotAvailableInEnovia.xlsx"); 
      }
    
  };

  return (
    <div className='fls-allow-portal-modal__excel-result__line'>
        <label className='fls-allow-portal-modal__excel-result__line__label'>Part(s) Not Added</label>
        <label className='fls-allow-portal-modal__excel-result__line__label'>
        <label className='fls-allow-portal-modal__excel-result__line__label'>{allowPortalStore.partsWithNoEnoviaDataExcelUpload?.length} </label>
        <a className='fls-allow-portal-modal__textwithunderline' onClick={(e)=>exportToXLSX()}>Part Details
        </a>
        </label>
      </div>
  );
});



