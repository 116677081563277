import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as React from 'react';
import { deleteIcon } from '../../../foundation/svg/delete-icon';
import { IPCCustomerPartDetails, PartDetails } from '../../ApiClient/ApiClient';
interface PartsTableProps {
  data: IPCCustomerPartDetails | undefined | null;
  deleteHandler: (part: PartDetails) => void;
}

export const PartDataTable: React.FC<PartsTableProps> = ({ data, deleteHandler }) => {
  return (
    <section className='fls-sp-collection__content-section'>
      <Table className='fls-data-tablePC'>
        <TableHead className='fls-data-tablePC__header'>
          <TableRow className='fls-data-tablePC__row'>
            <TableCell
              sx={{ color: 'white', fontSize: 14, fontWeight: 'bold' }}
              className='fls-font__small-bold fls-data-tablePC__header'
            >
              Part Number
            </TableCell>
            <TableCell
              sx={{ color: 'white', fontSize: 14, fontWeight: 'bold' }}
              className='fls-font__small-bold fls-data-tablePC__header'
            >
              Customer Part Number
            </TableCell>
            <TableCell
              sx={{ color: 'white', fontSize: 14, fontWeight: 'bold' }}
              className='fls-font__small-bold fls-data-tablePC__header'
            >
              Currency
            </TableCell>
            <TableCell
              sx={{ color: 'white', fontSize: 14, fontWeight: 'bold' }}
              className='fls-font__small-bold fls-data-tablePC__header'
            >
              Price
            </TableCell>
            <TableCell
              sx={{ color: 'white', fontSize: 14, fontWeight: 'bold' }}
              className='fls-font__small-bold fls-data-tablePC__header'
            >
              Agreed IncoTerm
            </TableCell>
            <TableCell
              sx={{ color: 'white', fontSize: 14, fontWeight: 'bold' }}
              className='fls-font__small-bold fls-data-tablePC__header'
            >
              Incoterm Location
            </TableCell>
            <TableCell
              sx={{ color: 'white', fontSize: 14, fontWeight: 'bold' }}
              className='fls-font__small-bold fls-data-tablePC__header'
            >
              Lead Time
            </TableCell>
            <TableCell
              sx={{ color: 'white', fontSize: 14, fontWeight: 'bold' }}
              className='fls-font__small-bold fls-data-tablePC__header'
            >
              Minimum Order Quantity
            </TableCell>
            <TableCell
              sx={{ color: 'white', fontSize: 14, fontWeight: 'bold' }}
              className='fls-font__small-bold fls-data-tablePC__header'
            >
              Unit of Measure
            </TableCell>
            <TableCell
              align='left'
              sx={{ color: 'white', fontSize: 14, fontWeight: 'bold' }}
              className='fls-font__small-bold fls-data-tablePC__header'
            ></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.partDetails?.map((row) => (
            <TableRow
              key={row.partId}
              className='fls-data-tablePC__row'
              sx={{ '&:last-child td, &:last-child th': { border: 0 }, fontSize: 12 }}
            >
              <TableCell sx={{ fontSize: 14 }}>{!row.partId ? 'Missing Part ID' : row.partId}</TableCell>
              <TableCell sx={{ fontSize: 14 }}>
                {!row.customerPartId ? 'Missing Customer Part ID' : row.customerPartId}
              </TableCell>
              <TableCell sx={{ fontSize: 14 }}>{!row.currency ? 'Missing Currency' : row.currency}</TableCell>
              <TableCell sx={{ fontSize: 14 }}>{!row.price ? 'Missing Price' : row.price}</TableCell>
              <TableCell sx={{ fontSize: 14 }}>{!row.incoTerm ? 'Missing IncoTerm' : row.incoTerm}</TableCell>
              <TableCell sx={{ fontSize: 14 }}>
                {!row.incoTermLocation ? 'Missing IncoTerm Location' : row.incoTermLocation}
              </TableCell>
              <TableCell sx={{ fontSize: 14 }}>{!row.leadTime ? 'Missing Lead Time' : row.leadTime}</TableCell>
              <TableCell sx={{ fontSize: 14 }}>
                {!row.minimumOrderQuantity ? 'Missing Minimum Order Quantity' : row.minimumOrderQuantity}
              </TableCell>
              <TableCell sx={{ fontSize: 14 }}>{!row.unitOfMeasure ? '-' : row.unitOfMeasure}</TableCell>
              <TableCell align='right' sx={{ fontSize: 14, float: 'inline-end' }}>
                <button
                  className='fls-data-tablePC__edit-button fls-data-tablePC__edit-button-mini-modal-item--delete'
                  onClick={() => deleteHandler(row)}
                >
                  <span>{deleteIcon()}</span>
                  <p>Delete</p>
                </button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </section>
  );
};
