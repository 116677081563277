import { action, makeAutoObservable } from 'mobx';
import { DataCellType, ExtendedCellData, ITableData, TableData } from '../components/DataTable/DataTableModels';
import { ICheckboxProps } from '../components/InputFields/Checkbox/Checkbox';
import { AddNewPortalModal, EnoviaAddPartSuccessModal, EnoviaAvilabilityForPartNumberModal, HandleEmptyPartNumberModal } from '../components/Modal/ModalComponents';
import { IModalRootWrapperProps, ModalType } from '../components/Modal/ModalRootWrapper';
import { RoleTypes } from '../constants/GenericContants';
import { ExcelUploadModal } from '../features/AllowPortal/Modals/ExcelUploadModal';
import { ExcelUploadResultModal } from '../features/AllowPortal/Modals/ExcelUploadResultModal';
import {
  AddAllowListPartRequest,
  AddAllowListPartResponse,
  AddAllowListPartResponseEnvelope,
  AddAllowListPartsFromExcelResponseEnvelope,
  AllowListGetAllPartRequest,
  AllowListItem,
  Client,
  FacetRequest,
  FileParameter,
  GetAllAllowListItemsResponseEnvelope,
  IAddAllowListPartResponse,
  IAddAllowListPartsFromExcelResponse,
  UpdateAllowListPartRequest
} from '../features/ApiClient/ApiClient';
import { ApiCallState, ApiState } from '../features/ApiClient/ApiHelperTypes';
import { RootStore } from './StoreDistributor';

interface AllowPortalFacetValue {
  matches: number;
  selectedMatches: number;
  isSelected: boolean;
}

interface AllowPortalFacets {
  [key: string]: {
    [key: string]: AllowPortalFacetValue;
  };
}

export default class AllowPortalStore {
  rootStore: RootStore;
  addPartNumber: string = '';
  excelAllowList?: File;
  excelUploadResult: ApiState<IAddAllowListPartsFromExcelResponse> = { state: ApiCallState.idle };
  addPartResult: ApiState<IAddAllowListPartResponse> = { state: ApiCallState.idle };

  partsWithNoEnoviaDataExcelUpload:AddAllowListPartResponse[]|undefined;


  currentPage: number = 1; // Starting from 1
  lastPage: number = 1;
  itemsPerPage: number = 10; // default amount of items per page.
  totalItems: number = 0;

  searchString: string = '';
  error: { title: string; status: string } | undefined = undefined;
  sortElement: string = '';
  sortDirection: number = 0;

  facets: AllowPortalFacets = {};

  openFacets: string[] = []; //If a value is provided here, it is open by default
  toggledFacets: string[] = [];

  allowPortalData: ITableData<any> = new TableData<any>();
  allowPortalHeaderData: any = {};

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.addPartNumber = '';
    makeAutoObservable(this, {
      GetInitialAllowPortalData: action, // Declare it as an action
    });
  }
  hideComment = (rootStore: RootStore) => {
    const roles = rootStore.userStore.getUserRoles() ?? [];
    return !roles.includes(RoleTypes.SPCAdmin);
  };

  public setExcelFile = (file?: File) => {
    this.excelAllowList = file;
  };

  public toggleFacet = (facet: string) => {
    if (this.openFacets.includes(facet)) {
      this.openFacets = this.openFacets.filter((openFacet) => openFacet !== facet);
    } else {
      this.openFacets = [...this.openFacets, facet];
    }
  };

  public FetchAllowPortalData() {
    const request = new AllowListGetAllPartRequest();
    request.facetRequests = [];
    request.search = this.searchString;
    request.pageNumber = this.currentPage;
    request.itemsPerPage = this.itemsPerPage;
    request.facetRequests = [
      new FacetRequest({ facetName: 'ProductLine', facetValues: this.GetToggledFacets('ProductLine') }),
      new FacetRequest({ facetName: 'EnoviaState', facetValues: this.GetToggledFacets('EnoviaState') }),
      new FacetRequest({ facetName: 'CatalogueState', facetValues: this.GetToggledFacets('CatalogueState') }),
    ];

    request.sortingDirection = this.getSortingDirection();
    request.sortingBy = this.sortElement + 'Utc';
    this.updateQueryParams();
    this.RequestAllowPortalData(request);
  }

  // Method to set all query parameters
  updateQueryParams() {
    const url = new URL(window.location.href);

    url.searchParams.set('page', this.currentPage.toString());
    url.searchParams.set('search', this.searchString);
    url.searchParams.set('itemsPerPage', this.itemsPerPage.toString());
    url.searchParams.set('sortingBy', this.sortElement + 'Utc');
    url.searchParams.set('sortingDirection', this.getSortingDirection());
    url.searchParams.set('productLine', this.GetToggledFacets('ProductLine').join(','));
    url.searchParams.set('enoviaState', this.GetToggledFacets('EnoviaState').join(','));
    url.searchParams.set('catalogueState', this.GetToggledFacets('CatalogueState').join(','));

    // Update URL without reloading the page
    window.history.pushState({}, '', url.toString());
  }

  // Method to clear the relevant query parameters
  clearQueryParams() {
    const url = new URL(window.location.href);

    // Remove all specific query parameters
    url.searchParams.delete('page');
    url.searchParams.delete('search');
    url.searchParams.delete('itemsPerPage');
    url.searchParams.delete('sortingBy');
    url.searchParams.delete('sortingDirection');
    url.searchParams.delete('productLine');
    url.searchParams.delete('enoviaState');
    url.searchParams.delete('catalogueState');

    // Update the URL to reflect cleared parameters
    window.history.pushState({}, '', url.toString());
  }

  public ClearSearch() {
    this.toggledFacets = [];
    this.clearQueryParams();

    for (const facetName in this.facets) {
      const facet = this.facets[facetName];
      for (const value in facet) {
        if (facet.hasOwnProperty(value)) {
          facet[value].isSelected = false;
        }
      }
    }
    this.currentPage = 1;
    this.searchString = '';
    this.FetchAllowPortalData();
  }

  GetToggledFacets(facetKey: string): string[] {
    const values: string[] = [];
    this.toggledFacets.forEach((facet) => {
      const [key, value] = facet.split(':');
      if (key === facetKey) {
        values.push(value === 'blank' ? '' : value);
      }
    });

    return values;
  }

  public GetInitialAllowPortalData() {
    const request = new AllowListGetAllPartRequest();
    request.facetRequests = [];
    request.pageNumber = this.currentPage;
    request.itemsPerPage = this.itemsPerPage;
    request.facetRequests = [
      new FacetRequest({ facetName: 'ProductLine', facetValues: this.GetToggledFacets('ProductLine') }),
      new FacetRequest({ facetName: 'EnoviaState', facetValues: this.GetToggledFacets('EnoviaState') }),
      new FacetRequest({ facetName: 'CatalogueState', facetValues: this.GetToggledFacets('CatalogueState') }),
    ];

    let apiClient = new Client();
    return apiClient
      .getAllowListItems(request)
      .then((data: GetAllAllowListItemsResponseEnvelope) => {
        // Initialize facets
        this.allowPortalData = this.mapGetAllowListItem(data.content?.parts);
        data.content?.facets!.forEach((facet: any) => {
          this.facets[facet.name] = {};
          facet.facetValues.forEach((facetValue: any) => {
            this.facets[facet.name][facetValue.value] = {
              matches: facetValue.matches,
              selectedMatches: facetValue.matches,
              isSelected: false,
            };
          });
        });
        this.totalItems = data.content?.totalCount ?? 0;
        this.updatePageCount(this.itemsPerPage);
        this.lastPage = Math.ceil(this.totalItems / this.itemsPerPage);
      })
      .catch((error) => {
        this.error = { title: 'Data Fetch Error', status: error.toString() };
      });
  }

  public GetFacetDropdownData(facetName: string): ICheckboxProps[] {
    const facetValues = this.facets[facetName];
    if (facetValues) {
      return Object.keys(facetValues).map((facetValue) => ({
        value: facetValue,
        facetName: facetName,
        displayValue: facetValue,
        selected: this.facets[facetName][facetValue].isSelected,
        totalCount: this.facets[facetName][facetValue].matches,
        matches: this.facets[facetName][facetValue].selectedMatches,
        toggleCheckbox: (value: string) => {
          this.facets[facetName][value].isSelected = !this.facets[facetName][value].isSelected;
          var facetDisplayValue = facetName + ':' + value;
          if (this.facets[facetName][value].isSelected) {
            this.addFacetDisplay(facetDisplayValue);
          } else {
            this.removeFacetDisplay(facetDisplayValue);
          }
          this.FetchAllowPortalData();
        },
      }));
    }
    return [];
  }

  addFacetDisplay = (name: string) => {
    if (!this.toggledFacets.includes(name)) {
      this.toggledFacets.push(name);
    }
  };

  removeFacetDisplay = (name: string) => {
    this.toggledFacets = this.toggledFacets.filter((x) => x !== name);
  };

  mapFacetKeyToDisplay = (facetKey: string): string => {
    switch (facetKey) {
      case 'ProductLine':
        return 'Product Line';
      case 'EnoviaState':
        return 'Enovia State';
      case 'CatalogueState':
        return 'Catalogue State';
      default:
        return facetKey;
    }
  };

  getSortingDirection = (): string => {
    switch (this.sortDirection) {
      case 1:
        return '';
      case 2:
        return 'asc';
      case 3:
        return 'desc';
      default:
        return '';
    }
  };

  RequestAllowPortalData(request: AllowListGetAllPartRequest) {
    let apiClient = new Client();
    apiClient
      .getAllowListItems(request)
      .then((data: GetAllAllowListItemsResponseEnvelope) => {
        this.allowPortalData = this.mapGetAllowListItem(data.content?.parts);
        Object.keys(this.facets).forEach((facetName) => {
          Object.keys(this.facets[facetName]).forEach((facetValue) => {
            this.facets[facetName][facetValue].selectedMatches = 0;
          });

          if (data.content?.facets && Array.isArray(data.content.facets)) {
            data.content.facets.forEach((facet) => {
              if (facet?.facetValues && Array.isArray(facet.facetValues)) {
                facet.facetValues.forEach((facetValue: any) => {
                  this.facets[facet.name as string][facetValue.value].selectedMatches = facetValue.matches;
                });
              }
            });
          }

          this.totalItems = data.content?.totalCount ?? 0;
          this.updatePageCount(this.itemsPerPage);
          this.lastPage = Math.ceil(this.totalItems / this.itemsPerPage);
        });
      })
      .catch((error) => {
        this.error = { title: 'Data Fetch Error', status: error.toString() };
      });
  }

  updatePageCount(itemsPerPage: number) {
    this.itemsPerPage = itemsPerPage;
    this.lastPage = Math.ceil(this.totalItems / this.itemsPerPage);
  }

  mapGetAllowListItem(data: AllowListItem[] | undefined): ITableData<any> {
    var tableData = new TableData<any>();

    if (!data) return this.allowPortalData;
    data.map((item) => {
      if (
        item === undefined ||
        item.partNumber === undefined ||
        item.status === undefined ||
        item.enoviaState === undefined ||
        item.level === undefined ||
        item.quotable === undefined ||
        item.createdUtc === undefined ||
        item.lastModifiedUtc === undefined
      ) {
        console.error(item, 'has undefined members');
        return this.allowPortalData;
      }
      item.partPricingCategory = item.partPricingCategory ?? 'None';

      var tableRow = {
        PartNumber: {
          value: item.partNumber,
          partNumber: item.partNumber,
        },
        CatalogueState: {
          value: item.status,
          partNumber: item.partNumber,
          cellType: DataCellType.Dropdown,
          dropdownOptions: ['Active', 'Inactive'],
          callBack: (cell: ExtendedCellData, cellValue: string) => {
            const updateRequest = new UpdateAllowListPartRequest();
            updateRequest.partNumber = cell.partNumber;
            updateRequest.catalogueState = cellValue;
            this.updatePartAPICall(updateRequest);
          },
        },
        EnoviaState: {
          value: item.enoviaState,
          partNumber: item.partNumber,
        },
        Level: {
          value: item.level,
          partNumber: item.partNumber,
          cellType: DataCellType.Dropdown,
          dropdownOptions: ['L1', 'L2', 'L3'],
          callBack: (cell: ExtendedCellData, cellValue: string) => {
            const updateRequest = new UpdateAllowListPartRequest();
            updateRequest.partNumber = cell.partNumber;
            updateRequest.level = cellValue;
            this.updatePartAPICall(updateRequest);
          },
        },
        PartPricingCategory: {
          value: item.partPricingCategory,
          partNumber: item.partNumber,
          cellType: DataCellType.Dropdown,
          dropdownOptions: ['None', 'Vendor Parts', 'Standard Parts', 'Unique Parts'],
          callBack: (cell: ExtendedCellData, cellValue: string) => {
            const updateRequest = new UpdateAllowListPartRequest();
            updateRequest.partNumber = cell.partNumber;
            updateRequest.partPricingCategory = cellValue === 'None' ? undefined : cellValue;
            this.updatePartAPICall(updateRequest);
          },
        },
        Quotable: {
          value: item.quotable.toString(),
          partNumber: item.partNumber,
          cellType: DataCellType.Quotable,
        },
        ProductLine: {
          value: item.productLine,
          partNumber: item.partNumber,
        },
        AlternativePartNumber: {
          value: item.duplicatePartNumbers?.join(', '),
          partNumber: item.partNumber,
        },
        SourceFromAurora: {
          value: (item.sourceSystem === 'Aurora').toString(),
          cellType: DataCellType.Checkbox,
          partNumber: item.partNumber,
          callBack: (cell: ExtendedCellData, cellValue: string) => {
            const updateRequest = new UpdateAllowListPartRequest();
            updateRequest.partNumber = cell.partNumber;
            updateRequest.sourceSystem = cellValue === 'true' ? 'Aurora' : '';
            this.updatePartAPICall(updateRequest);
          },
        },
        Comment: {
          value: item.comment ?? '',
          cellType: DataCellType.RestrictedCommentField,
          partNumber: item.partNumber,
          hidden: this.hideComment(this.rootStore),
          callBack: (cellValue: string, cell: ExtendedCellData) => {
            const updateRequest = new UpdateAllowListPartRequest();
            updateRequest.partNumber = cell.partNumber;
            updateRequest.comment = cellValue;
            this.updatePartAPICall(updateRequest);
          },
        },
        Created: {
          value: item.createdUtc?.toISOString().split('T')[0] ?? '-',
          partNumber: item.partNumber,
        },
        LastModified: {
          value: item.lastModifiedUtc?.toISOString().split('T')[0] ?? '-',
          partNumber: item.partNumber,
        },
      };
      tableData.addRow(tableRow);
      return null;
    });
    return tableData;
  }

  updatePartAPICall = (partRequest: UpdateAllowListPartRequest) => {
    let apiClient = new Client();
    apiClient.updatePart(partRequest);
  };

  public resetCurrentPage = () => {
    this.currentPage = 1;
  };
//start
  CreateNewAllowListPart() {
    let apiClient = new Client();
    const request = new AddAllowListPartRequest();

    request.partNumber = this.addPartNumber;

      apiClient.addAllowListPart(request)
    .then((result:AddAllowListPartResponseEnvelope)=>{
         this.addPartResult={state: ApiCallState.success, result: result.content}; 
         if(this.addPartResult?.result?.enoviaPartDetails?.[0].exists==false)
         {
          this.PostPartExistanceCheckModal();
         }
         else if(this.addPartResult.result?.enoviaPartDetails?.[0].exists==true){
          this.PostPartAddedSuccessModal();
         }
         else if(this.addPartResult.result?.enoviaPartDetails==undefined){
          this.PostPartExistanceCheckModal();
         }
               
    })
    
    .catch((error)=>{
      this.addPartResult={ state: ApiCallState.error, error: error.toString() };
    })
    
  }
  //end
  uploadExcel() {
    if (!this.excelAllowList) return;
    this.addUploadExcelModal();
    let apiClient = new Client();
    const request = { fileName: this.excelAllowList.name, data: this.excelAllowList } as FileParameter;
    this.excelUploadResult = { state: ApiCallState.loading };
    apiClient
      .addAllowListPartsFromExcel(request)
      .then((result: AddAllowListPartsFromExcelResponseEnvelope) => {
        this.excelUploadResult = { state: ApiCallState.success, result: result.content };
        if(this.excelUploadResult!=undefined)
        {
          this.partsWithNoEnoviaDataExcelUpload=this.excelUploadResult.result?.addAllowListPartResponse?.enoviaPartDetails?.filter(e=>e.exists==false)
        }
      })
      .catch((error) => {
        this.excelUploadResult = { state: ApiCallState.error, error: error.toString() };
      })
      .finally(() => {
        this.excelAllowList = undefined;
      });
  }
  addUploadExcelModal = () => {
    const modalConfig = {
      title: 'Upload Excel File To Add New Parts',
      slotComponent: <ExcelUploadResultModal />,
      buttonConfig: [
        {
          type: 'action',
          className: 'fls-modal__action-buttons--allow-portal__onebutton',
          label: 'OK',
          action: () => {
            this.rootStore.mainStore.setModalDisplayState(false);
          },
        },
      ],
      modalType: ModalType.AllowPortalModal,
    } as IModalRootWrapperProps;

    this.rootStore.mainStore.setModalConfig(modalConfig);
    this.rootStore.mainStore.setModalDisplayState(true);

  };

  public AddNewPartToAllowListModal = () => {
    const modalConfig = {
      title: 'Add new Part',
      slotComponent: <AddNewPortalModal />,
      buttonConfig: [
        {
          type: 'action',
          label: 'Cancel',
          action: () => {
            this.addPartNumber = '';
            this.rootStore.mainStore.setModalDisplayState(false);
          },
        },
        {
          type: 'action',
          label: 'Add new Part',
          action: () => {
            if(this.addPartNumber)
            {
              this.CreateNewAllowListPart();
              this.rootStore.mainStore.setModalDisplayState(false);
            }
            else
            {
              this.HandleEmptyPartNumberModal();
            }


           
          },
        },
      ],
      modalType: ModalType.AllowPortalModal,
    };
    this.rootStore.mainStore.setModalConfig(modalConfig);
    this.rootStore.mainStore.setModalDisplayState(true);
  };
//start modal popup for add new part response
public PostPartExistanceCheckModal = () => {
  if(this.addPartResult.result?.enoviaPartDetails?.[0].exists==true) return;
  if(this.addPartResult.result?.enoviaPartDetails!=undefined && this.addPartResult.result?.enoviaPartDetails?.[0].exists==false)
  {
    const modalConfig = {
      title: 'Availability Of Enovia Data',
      slotComponent: <EnoviaAvilabilityForPartNumberModal />,
      buttonConfig: [    
        {
          type: 'action',
          label: 'OK',
           className: 'fls-modal__action-buttons--allow-portal__onebutton',
          action: () => {
            this.addPartNumber = '';
            this.rootStore.mainStore.setModalDisplayState(false);     
            
          },
        },
      ],
      modalType: ModalType.AllowPortalModal,
    };
      this.rootStore.mainStore.setModalConfig(modalConfig);
  this.rootStore.mainStore.setModalDisplayState(true);
  }
  else{

    const modalConfig = {
      title: 'Part Not Added',
      slotComponent: <EnoviaAvilabilityForPartNumberModal />,
      buttonConfig: [    
        {
          type: 'action',
          label: 'OK',
           className: 'fls-modal__action-buttons--allow-portal__onebutton',
          action: () => {
            this.addPartNumber = '';
            this.rootStore.mainStore.setModalDisplayState(false);     
            
          },
        },
      ],
      modalType: ModalType.AllowPortalModal,
    };
    this.rootStore.mainStore.setModalConfig(modalConfig);
    this.rootStore.mainStore.setModalDisplayState(true);
  }


};
public PostPartAddedSuccessModal = () => {
  //if(!this.addPartResult) return;
  const modalConfig = {
    title: 'Part Added',
    slotComponent: <EnoviaAddPartSuccessModal />,
    buttonConfig: [    
      {
        type: 'action',
        label: 'OK',
         className: 'fls-modal__action-buttons--allow-portal__onebutton',
        action: () => {
          this.addPartNumber = '';
          this.rootStore.mainStore.setModalDisplayState(false);
        },
      },
    ],
    modalType: ModalType.AllowPortalModal,
  };
  this.rootStore.mainStore.setModalConfig(modalConfig);
  this.rootStore.mainStore.setModalDisplayState(true);
};

//handle empty part number

public HandleEmptyPartNumberModal = () => {
  const modalConfig = {
    title: 'Part Not Added',
    slotComponent: <HandleEmptyPartNumberModal />,
    buttonConfig: [    
      {
        type: 'action',
        label: 'OK',
         className: 'fls-modal__action-buttons--allow-portal__onebutton',
        action: () => {
          this.addPartNumber = '';
          this.rootStore.mainStore.setModalDisplayState(false);
        },
      },
    ],
    modalType: ModalType.AllowPortalModal,
  };
  this.rootStore.mainStore.setModalConfig(modalConfig);
  this.rootStore.mainStore.setModalDisplayState(true);
};

//end

//end modal popup for add new part response
  public addNewPartsExcelModal = () => {
    const modalConfig = {
      title: 'Upload Excel File To Add New Parts',
      slotComponent: <ExcelUploadModal />,
      buttonConfig: [
        {
          type: 'action',
          class: 'fls-button fls-button--primary-with-icon-outline',
          label: 'Cancel',
          action: () => {
            this.rootStore.mainStore.setModalDisplayState(false);
          },
        },
        {
          type: 'action',
          label: 'Upload the excel file',
          action: () => {
            if (this.excelAllowList) {
              this.uploadExcel();
            }
          },
        },
      ],
      modalType: ModalType.AllowPortalModal,
    } as IModalRootWrapperProps;
    this.rootStore.mainStore.setModalConfig(modalConfig);
    this.rootStore.mainStore.setModalDisplayState(true);
  };

  LoadQueryParamsFromUrl() {
    const url = new URL(window.location.href);

    this.currentPage = Number(url.searchParams.get('page')) || 1;
    this.searchString = url.searchParams.get('search') || '';
    this.itemsPerPage = Number(url.searchParams.get('itemsPerPage')) || 10;
    this.sortElement = (url.searchParams.get('sortingBy') || '').replace('Utc', '');
    this.sortDirection = this.getSortDirectionFromUrl(url.searchParams.get('sortingDirection') || '');

    this.populateFacetFromQueryParam('ProductLine', url.searchParams.get('productLine'));
    this.populateFacetFromQueryParam('EnoviaState', url.searchParams.get('enoviaState'));
    this.populateFacetFromQueryParam('CatalogueState', url.searchParams.get('catalogueState'));

    return {
      page: this.currentPage,
      search: this.searchString,
      itemsPerPage: this.itemsPerPage,
      sortingBy: this.sortElement + 'Utc',
      sortingDirection: url.searchParams.get('sortingDirection'),
      productLine: url.searchParams.get('productLine'),
      enoviaState: url.searchParams.get('enoviaState'),
      catalogueState: url.searchParams.get('catalogueState'),
    };
  }

  AreQueryParamsSet() {
    const url = new URL(window.location.href);

    // Check if any of these parameters are set (not empty, null, or default values)
    const pageSet = url.searchParams.has('page') && Number(url.searchParams.get('page')) > 1;
    const searchSet = url.searchParams.has('search') && url.searchParams.get('search') !== '';
    const itemsPerPageSet = url.searchParams.has('itemsPerPage') && Number(url.searchParams.get('itemsPerPage')) !== 10;
    const sortingBySet = url.searchParams.has('sortingBy') && url.searchParams.get('sortingBy') !== '';
    const sortingDirectionSet =
      url.searchParams.has('sortingDirection') && url.searchParams.get('sortingDirection') !== '';
    const productLineSet = url.searchParams.has('productLine') && url.searchParams.get('productLine') !== '';
    const enoviaStateSet = url.searchParams.has('enoviaState') && url.searchParams.get('enoviaState') !== '';
    const catalogueStateSet = url.searchParams.has('catalogueState') && url.searchParams.get('catalogueState') !== '';

    // Return true if any of the conditions are true, meaning any query parameter is set
    return (
      pageSet ||
      searchSet ||
      itemsPerPageSet ||
      sortingBySet ||
      sortingDirectionSet ||
      productLineSet ||
      enoviaStateSet ||
      catalogueStateSet
    );
  }

  // Helper method to map the sorting direction string to the store's sortDirection value
  getSortDirectionFromUrl(sortingDirection: string): number {
    switch (sortingDirection) {
      case 'asc':
        return 2;
      case 'desc':
        return 3;
      default:
        return 1; // Default sort direction (no sorting)
    }
  }

  // Helper function to populate facet and ensure `isSelected` is set to true
  populateFacetFromQueryParam(facetName: string, facetValueStr: string | null) {
    if (facetValueStr) {
      facetValueStr.split(',').forEach((value) => {
        const facetDisplayValue = `${facetName}:${value}`;
        this.toggledFacets.push(facetDisplayValue);
        if (this.facets[facetName] && this.facets[facetName][value]) {
          this.facets[facetName][value].isSelected = true;
        } else {
          console.error(`Facet ${facetName}:${value} not found in facets`);
        }
      });
    }
  }
}
